import { DocumentType, UserType } from '@innedit/innedit-type';
import React, { FC, useEffect, useState } from 'react';

import { ListItemProps } from '../../../containers/Espace/props';
import Item from './index';

const ListItemContact: FC<ListItemProps<UserType>> = ({
  docId,
  index,
  onClick,
  model,
}) => {
  const [doc, setDoc] = useState<DocumentType<UserType>>();

  useEffect(() => {
    const unsub = model.watchById(docId, document => {
      setDoc(document);
    });

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [docId, model]);

  if (!doc) {
    return null;
  }

  const name = [];
  if (doc.firstName) {
    if (doc.lastName) {
      name.push(`${doc.firstName} ${doc.lastName}`);
    } else {
      name.push(doc.firstName);
    }
  } else if (doc.lastName) {
    name.push(doc.lastName);
  }

  return (
    <Item
      contentClassName="grid gap-2 grid-cols-12"
      doc={doc}
      index={index}
      onClick={onClick}
    >
      <strong className="col-span-3 flex justify-start items-center">
        {name.join(' - ')}
      </strong>
      <div className="col-span-5 flex justify-start items-center space-x-2">
        {/* {doc.subscribedToNewsletter && <Icon value={IconNewspaper} />} */}
        {doc.email && <span>{doc.email}</span>}
      </div>
    </Item>
  );
};

export default ListItemContact;
